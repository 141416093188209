import Main_bg from '../assets/images/main_page_bg.png'
import { Link } from 'react-router-dom';

const MainPage = () => {

    return (
        <div className="container">
            <div className="main-page">
                <div className="main-page_text">
                    {/* <h2 className="black">
                        Lorem ipsum dolor sit amet
                    </h2> */}
                    <h1 className="black">
                        Crypto Vesting Platform
                    </h1>
                    <p className="black">
                        The platform provides a simple and user-friendly interface for crypto projects to easily manage their vesting needs.

                        Claim now  ensures secure and transparent transactions. Once you setup smart contract and vesting rules for your team or investors, they see full schedule and feel schedule and build confidence and trust.

                        Already distributed token value 3.791.638 USDT
                    </p>
                    <Link to='/0xD3042E5CCFf74040d43b4Ba340ccf4f93868568c' className="black">
                        Claim
                    </Link>
                </div>
                <div className="main-page_img">
                    <img src={Main_bg} alt="" />
                </div>
            </div>
        </div>
    );
}
export default MainPage;